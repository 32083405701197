import React from "react"
import Header from "../components/Header/header"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import "../assets/sass/theme/pages/services.scss"
import CtaBanner from "../components/CtaBanner/CtaBanner"

const WorkshopEquipmentPage = () => {
  return (
    <Layout>
      <Header isHeaderSolid />
      <SEO
        title="Workshop & Equipment"
        description="CNR Engineering have a quality management system to ISO 9001;2015 and are CE Approved to EN1090-2. See more information about our workshop & equipment."
      />
      <main className="services with-spacing">
        <div className="page-width">
          <div className="services__inner">
            <div className="service__inner_left">
              <h1 className="title">Workshop & Equipment</h1>
              <p>We have a Quality management system to ISO 9001;2015 and are CE Approved to EN1090-2.</p>
              <ul>
                <li>Rolls 1830 x 12mm Hausler</li>
                <li>Adira 125/3000 press brake. 3000mm x 8mm 125 ton</li>
                <li>Pierson 13/3000mm guillotine</li>
                <li>100 ton open fronted straightening press</li>
                <li>30 ton straightening press</li>
                <li>140 ton Kingsland metal crop & punch</li>
                <li>50 ton Kingsland metal crop & punch</li>
                <li>Radial arm drill, pillar drills & magnetic drills</li>
                <li>Bomar band saw varied mitres 500 x 300</li>
                <li>Bomar fully automatic band saw 300 x 300</li>
                <li>Tube bending up to 125mm</li>
                <li>Rigid pipe threading equipment</li>
                <li>Welding: 450 amp mig welders, 400 amp twin pulse mig, 400 amp Tig CNC 4000mm x 2000mm profiling table, 0.5mm-200mm cutting thickness</li>
                <li>Colchester Mascott lathe 80" between centres</li>
                <li>Colchester Mastiff lathe 120" between centres</li>
                <li>Rambaudi milling machine</li>
                <li>XYZ Vulcan 1610 VMC milling machine</li>
                <li>Binns & Berry 2m CNC lathe</li>
                <li>Various cranes & forklifts from 3 tons - 46 tons</li>
                <li>Hydraulic hose assembly workshop, JIC BSP Metric ORFS Komatsu</li>
              </ul>
            </div>
            <div className="service__inner_right" style={{backgroundImage: "url('/static/loading-82facc05a59632b21fee8af747679af7.jpg')", backgroundPosition: "center center"}}></div>
          </div>
        </div>
      </main>
      <CtaBanner ctaText="Find out more about equipment and services" buttonText="Speak to our experts" />
    </Layout>
  )
}

export default WorkshopEquipmentPage
